

.homeContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--light-primary-color);
    background-image: url("./furi.svg");
    background-repeat: no-repeat;
  background-size: cover;

}



.titleBlock {
    margin-top: 90px;
    .title {
  font-size: 2rem;
  color: var(--primary-color);
}

.intro {
    margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: var(--text-color);
}
}


.section {
  width: 100%;
  min-height: 50vh;
}

.analyzeSection {
    background-color: var(--background-color);
  animation: fadeIn 1s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
}

.analyzeTitle {
  margin-bottom: 30px;
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
  padding-bottom: 30px;
  border-radius: 10px;

}

.analyzeContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.appImg {
    width: 500px;
}

.analyzeBlock{
    flex: 1;
}
.analyzeButton {
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  background-color: var(--primary-color);
  color: white;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: var(--primary-color-hover);
    transform: translateY(-5px);
    color: var(--primary-color);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@var(--primary-color): #007bff;