.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 240px); /* 减去头部和底部的高度 */
  background-color: var(--secondary-color); /* 使用辅助色 */
  overflow: auto; /* 允许内容滚动 */
  padding: 60px 0; /* 为头部和底部留出空间 */
  position: relative; /* 为了定位背景图案 */

  /* 调整圆点颜色为稍深的米白色 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle, rgba(205, 235, 224, 0.3) 6px, transparent 6px); /* 增加透明度 */
    background-size: 30px 30px; /* 调整圆点的间距以增加密集度 */
    background-position: 15px 15px; /* 调整圆点的起始位置 */
    z-index: 0;
  }
}

.register-form {
  position: relative; /* 确保表单在背景图案之上 */
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* 确保表单宽度 */
  z-index: 1; /* 确保表单在背景图案之上 */
}

.register-form h2 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.register-form input {
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.register-form button {
  width: 100%;
}

.privacy-links {
  margin-top: 15px;
  text-align: center;
  font-size: 0.9em;
  color: var(--text-color);

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@var(--primary-color): #007bff;