.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.layoutHeader {
    width: 100%;
  background-color: var(--primary-color); /* 使用主色 */
  color: var(--secondary-color); /* 使用米白色 */
}


.logoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
      height: 80px;
}

.logo {
  height: 58px;
}

.headerRight {
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  padding-right: 30px;
}

.header-right span {
  margin-right: 15px;
}

.change-password {
  color: var(--secondary-color);
  text-decoration: none;
  margin-left: 10px;
}

.change-password:hover {
  text-decoration: underline;
}

.layoutFooter {
  background-color: var(--text-color);
  color: white;
  text-align: center;
  padding: 10px 0;
  height: 120px;
}

.layoutContainer {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* 确保页面至少占满整个视口 */
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.layoutContent {
  flex: 1;
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

@media (max-width: 768px) {
  .layoutContent {
    width: 100%;
  }

  .menu ul {
    flex-direction: column;
    align-items: center;
  }

  .menu-left ul li,
  .menu-right ul li {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@var(--primary-color): #007bff;