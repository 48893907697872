/* CSS Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #6a0dad; /* 主色紫色 */
  --sub-primary-color: #b555fa; /* 主色紫色 */
  --light-primary-color: #eee1f8e0; /* 主色紫色 */
  --secondary-color: #f5f5dc; /* 辅助色米白色 */
  --background-color: #f5f5f5; /* 背景色 */
  --sub-secondary-color: #d6b4df; /* 辅助色米白色 */
  --text-color: #333; /* 文字颜色 */
  --dark-color: #232323; /* 黑色系 */
  --alert-color: #f44336; /* 警告色 */
}

html, body {
  min-height: 100%;
  font-family: Arial, sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
  overflow: auto; 
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
  background: none;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Additional global styles can be added here */

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--text-color);
}

h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--text-color);
}

h3 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: var(--text-color);
}

h5 {
  font-size: 1.25rem;
  margin-bottom: 5px;
  color: var(--text-color);
}

h6 {
  font-size: 1rem;
  margin-bottom: 5px;
  color: var(--text-color);
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}