@primary-color: #6a0dad;
@secondary-color: #fff;
@text-color: #333;
@gray-color: #ddd;
@sub-primary-color: #b555fa;
@alert-color: #f44336;
@light-primary-color: #eee1f8e0;
@sub-secondary-color: #d6b4df;
@background-color:#f5f5f5;

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

h2 {
  color: var(--text-color);
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #fff;
  color: #333;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ddd;
  }
}

.primaryButton {
  background-color: var(--primary-color);
  color: white;

  &:hover {
    background-color: var(--sub-primary-color);
  }
}

.secondaryButton {
  background-color: var(--secondary-color);
  color: var(--text-color);

  &:hover {
    background-color:var(--sub-primary-color);
  }
}

.grayButton {
  border: 1px solid var(--gray-color);
  color: var(--text-color);

  &:hover {
    background-color: var(--gray-color);
  }
}

@media screen and (min-width: 1200px) {
  .centerContent {
    width: 1200px;
    margin: 0 auto;
  }
}
