.applicationsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); // 使用网格布局
  gap: 20px;
  padding: 10px;
  justify-items: center; // 居中对齐每个项目
}

.applicationItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 100%; // 使用网格布局时，宽度为100%
  max-width: 300px; // 最大宽度
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.applicationItem:hover {
  transform: translateY(-5px);
}

.applicationImage {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 12px;
  object-fit: cover;
}

@var(--primary-color): #007bff;