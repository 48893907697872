.text-analyze-main{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.text-analyze-header{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    font-size: 0.9rem;
    color: #666;
}

.input-text-button{
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-color);
}

.text-analyze-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-width: 100%;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.menu-button,
.analyze-button,
.save-button
 {
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--sub-primary-color);
  }
  &:disabled{
    background-color: #ccc;
    cursor: not-allowed;
  }
}

.icon-group{
  display: flex;
  margin-bottom: 20px;
  margin-top: 40px;
  gap: 10px;
  margin-bottom: 20px;
}

.text-input {
  width: 100%;
  min-height: 230px; /* 设置默认最低高度 */
  height: auto;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd; /* 调整边框为浅色 */
  border-radius: 5px;
  margin-bottom: 20px;
  resize: vertical; /* 允许用户垂直调整高度 */
  background-color: #fff;
  overflow-y: auto; /* 确保滚动条可见 */

  /* 美化滚动条 */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }

  &:focus {
    border-color: #b3b3b3; /* 设置获得焦点时的边框颜色为浅色 */
    outline: none; /* 移除默认的焦点外框 */
  }
}

@media (max-width: 768px) {
  .text-input {
    min-height: 100px; /* 设置移动设备上的最小高度 */
  }
}

.error-message{
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
}

.clear-button {
  padding: 10px 30px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color:  var(--sub-primary-color);
  color: var(--secondary-color);
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--sub-secondary-color);
  }
}

.text-result {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.furigana-text {
    width: 100%;
    text-align: left;
  line-height: 1;
  margin-bottom: 20px;
  .word-container{
    margin: 10px 5px;
    display: inline-block;
  }
  rt{
color:rgb(92, 86, 86);
font-size:0.7em;
  }

  &> div{
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .noun {
    color: #000;
    
  }

  .verb {
    border-bottom: 1px solid var(--sub-primary-color);
  }

  .adjective {
    border-bottom: 1px solid var(--primary-color);
    
  }

  .default {
    color: #444;
  }
  
}




@var(--primary-color): #007bff;