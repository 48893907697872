.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 240px); /* 减去头部和底部的高度 */
  background-color: var(--secondary-color); /* 使用辅助色 */
  overflow: auto; /* 允许内容滚动 */
  padding: 60px 0; /* 为头部和底部留出空间 */
}

.login-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* 确保表单宽度 */
}

.login-form h2 {
  margin-bottom: 20px;
  color: var(--text-color);
}

.login-form input {
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
}

@var(--primary-color): #007bff;