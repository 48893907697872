
.searchSection {
  padding: 20px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.searchInput {
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--primary-color); /* 使用 var(--primary-color) */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  flex: 1; /* 使输入框占据剩余空间 */
  height: 46px; /* 与按钮高度一致 */
  box-sizing: border-box; /* 包含内边距和边框 */
  &:focus {
    border-color: var(--primary-color); /* 获取焦点时颜色不变 */
    outline: none;
  }
}

.searchButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: var(--primary-color);
  color: white;
  transition: background-color 0.3s;
  height: 46px; /* 与输入框高度一致 */

  &:hover {
    color: var(--secondary-color);
  }
}
@var(--primary-color): #007bff;