.dictionaryContainer {
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.description {
  font-size: 1.2rem;
  color: #666;
}

.searchSection {
  margin-bottom: 40px;
}

@var(--primary-color): #007bff;