@import '../../assets/css/styles.less'; // 确保路径正确

.menu {
  width: 100%;
  background-color: var(--primary-color); 
  color: var(--secondary-color);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.menu-left ul,
.menu-right ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu-left ul li,
.menu-right ul li {
  margin-right: 15px;
}

.menu-right ul li:last-child {
  margin-right: 0;
}

.menu a {
  text-decoration: none;
  color: var(--secondary-color);
  transition: color 0.3s;
}

.menu a:hover {
  color: white;
}

.menu button {
  background-color: var(--primary-color); /* 使用LESS变量 */
  color: var(--secondary-color);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.menu button:hover {
  background-color: darken(@primary-color, 10%);
}

@media screen and (max-width: 768px) {
  .menu {
    flex-direction: column;
    align-items: center;
    line-height: 2;
    height: auto;
    justify-content: start;
    li {
      margin-right: 0;  
      float: left;
      a{
        display: block;
        width: 100%;
        padding: 0 20px;
      }
    }
    .menuRight{
        display: none;
    }
  }
}
@var(--primary-color): #007bff;