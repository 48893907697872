.alertOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert {
  background-color: var(--background-color); /* 使用系统背景颜色 */
  padding: 50px;
  border-radius: 5px;
  color: #333;
  text-align: center;
  width: auto;
  max-width: 500px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dotContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.success .dot {
  background-color: #4caf50;
}

.error .dot {
  background-color: #f44336;
}

.info .dot {
  background-color: var(--primary-color);
}

.buttonGroup {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}


@var(--primary-color): #007bff;