@import '../../assets/css/styles.less';

.common-button {
  background-color: #6a0dad; /* 使用主色 */
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.common-button:hover {
  background-color: darken(#6a0dad, 10%); /* 使用主色的变体 */
}


@var(--primary-color): #007bff;